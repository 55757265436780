export const SET_SEARCH_PROJECT_SELECTED = "SET_SEARCH_PROJECT_SELECTED";
export const SET_SEARCH_PROJECTID_SELECTED = "SET_SEARCH_PROJECTID_SELECTED";
export const RESET_SEARCH_PROJECTID_SELECTED =
  "RESET_SEARCH_PROJECTID_SELECTED";

export const SET_SEARCH_PROJECT_PARAMETERS = "SET_SEARCH_PROJECT_PARAMETERS";
export const SET_SEARCH_PROJECT_FILTER = "SET_SEARCH_PROJECT_FILTER";
export const RESET_SEARCH_PROJECT_PARAMETERS =
  "RESET_SEARCH_PROJECT_PARAMETERS";
export const RESET_SEARCH_PROJECT_FILTER = "RESET_SEARCH_PROJECT_FILTER";

export const SET_SEARCH_PROJECT_DETAIL = "SET_SEARCH_PROJECT_DETAIL";
export const SET_SEARCH_PROJECT_DETAIL_DATA = "SET_SEARCH_PROJECT_DETAIL_DATA";
export const SET_SEARCH_PROJECT_DETAIL_DATA_LOAD =
  "SET_SEARCH_PROJECT_DETAIL_DATA_LOAD";

export const SET_SEARCH_PROJECT_DATA_LOAD = "SET_SEARCH_PROJECT_DATA_LOAD";
export const SET_SEARCH_PROJECT_DATA_VALUE_LOAD =
  "SET_SEARCH_PROJECT_DATA_VALUE_LOAD";
export const SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION_LOAD =
  "SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION_LOAD";

export const SET_SEARCH_PROJECT_DATA = "SET_SEARCH_PROJECT_DATA";
export const SET_SEARCH_PROJECT_DATA_VALUE = "SET_SEARCH_PROJECT_DATA_VALUE";

export const SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION =
  "SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION";

export const SET_SEARCH_PROJECT_DATA_ERROR = "SET_SEARCH_PROJECT_DATA_ERROR";
export const SET_SEARCH_PROJECT_DATA_VALUE_ERROR =
  "SET_SEARCH_PROJECT_DATA_VALUE_ERROR";
export const SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION_ERROR =
  "SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION_ERROR";

export const RESET_SEARCH_PROJECT_DATA = "RESET_SEARCH_PROJECT_DATA";
export const RESET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION =
  "RESET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION";

export const SET_SEARCH_PROJECT_WATCHLIST_FORM =
  "SET_SEARCH_PROJECT_WATCHLIST_FORM";

export const SET_SEARCH_PROJECT_ENQUIRY_FORM =
  "SET_SEARCH_PROJECT_ENQUIRY_FORM";

export const SET_SEARCH_PROJECT_SAVE_SEARCH = "SET_SEARCH_PROJECT_SAVE_SEARCH";

export const RESET_FILTER_PROJECT = "RESET_FILTER_PROJECT";
export const SET_SHOW_ALL_DATA_ON_MAP = "SET_SHOW_ALL_DATA_ON_MAP";
export const REDRAW_MAP = "REDRAW_MAP";