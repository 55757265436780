const initialState = {
  parameters: {
    // Parameters from filter search bar
    // Keyword
    keyword: "",
    advanceKeyword: {
      projectName: "",
      projectDescription: "",
      projectDocument: "",
    },
    // Location
    location: null,
    companyIds: [],
    advancedLocation: null,
    // Category
    categories: null,
    // Stage
    stage: null,
    // Project Value
    projectValue: null,
    // Date range
    lastUpdate: null,
    lastUpdateCustom: null,
    tenderClosingDateCustom: null,
    tenderClosingDateDay: null,
    constructionStartDateCustom: null,
    constructionStartDateDay: null,
    constructionEndDateCustom: null,
    constructionEndDateDay: null,
    // Project detail
    floorArea: null,

    siteArea: null,
    noOfStorey: null,
    noOfUnit: null,
    developmentType: null,
    ownershipType: null,
    allOwnership: null,
    foreignParticipantsProject: null,
    greenBuildingStatusId: null,
    greenBuildingSchemeId: null,
    anyGreenBuilding: null,
    projectDocument: null,
    projectImage: null,
    // role
    roleGroups: null,
    // Tender Type
    contractType: null,
    // other
    excludeAllFolder: null,
    showAllVersion: null,
    showVersionOneOnly: null,
    keyAccountFolder: null,
    // Exclude all watchlist value
    // 0 = no filter
    // 1= exclude all watchlist
    // 2 = exclue all team watchlist
    excludeAllWatchlist: 0,
    excludeMasterPlan: null,
    saveSearch: null,
    companyFilter: null,
    fromCompany: false,
  },

  filter: {
    limit: 25,
    sortby: "last_updated",
    offset: 0,
    last_update: "",
  },

  resultData: {
    projects: [],
    totalDataProjectAll: 0,
    loadData: false,
    error: null,
  },
  resultDataValue: {
    loadDataValues: false,
    error: null,
    totalData: 0,
    totalValue: 0,
    currencyCode: null,
    outsideSubsCount: 0,
  },
  resultDataOutsideSubscription: {
    outsideSubscriptions: [],
    loadSubs: false,
    error: null,
    totalDataSubs: 0,
  },
  resultDataOutsideSubscriptionLocation: {
    locations: [],
    loadLocation: false,
    error: null,
  },

  selected: [],
  selectedProjectId: [],
  detail: null,
  detailData: {
    loadData: false,
    detailData: null,
  },

  projectWatchlistForm: null,
  projectEnquiryForm: false,

  saveSearch: null,
  showAllDataOnMap: false,
  redrawMap: null
};

export default initialState;
