import * as Sentry from "@sentry/react";
import appConfig from "@tnlm/config/app.config";

const ignoreErrors: (string | RegExp)[] = [
  "UnhandledRejection",
  "ChunkLoadError",
  "zaloJSV2",
  "Loading CSS chunk",
];

const profile = JSON.parse(localStorage.getItem("profile") as string);

Sentry.setUser({
  id: profile?.id || "",
  username: profile?.username || "",
});

Sentry.init({
  // Init Setup
  dsn: appConfig.tnlmSentryDSN,
  // integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  integrations: [new Sentry.BrowserTracing()],

  // Performance Monitoring
  // tracesSampleRate: appConfig.tnlmEnv === "PRODUCTION" ? 0.2 : 1.0, // Capture 100% of the transactions, reduce in production!
  tracesSampleRate: 0.2,

  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  // Errors ignored
  ignoreErrors,

  enabled: !appConfig.isDevelopment,
});

export default Sentry;
