export const SET_LIST_COMBO_CATEGORY = "SET_LIST_COMBO_CATEGORY";
export const SET_LIST_COMBO_LOCATION = "SET_LIST_COMBO_LOCATION";
export const SET_LIST_COMBO_STAGE = "SET_LIST_COMBO_STAGE";
export const SET_LIST_COMBO_ROLE = "SET_LIST_COMBO_ROLE";
export const SET_LIST_COMBO_ROLE_SETTING = "SET_LIST_COMBO_ROLE_SETTING";
export const SET_LIST_PROJECT_SAVED = "SET_LIST_PROJECT_SAVED";
export const SET_LIST_COMPANY_SAVED = "SET_LIST_COMPANY_SAVED";
export const SET_LIST_GREEN_BUILDING = "SET_LIST_GREEN_BUILDING";
export const SET_LIST_SALES_PIPELINE = "SET_LIST_SALES_PIPELINE";
export const SET_LIST_KEY_ACCOUNT = "SET_LIST_KEY_ACCOUNT";
export const SET_LIST_DEVELOPMENT_TYPE = "SET_LIST_DEVELOPMENT_TYPE";
export const SET_LIST_OWNERSHIP_TYPE = "SET_LIST_OWNERSHIP_TYPE";
export const SET_LIST_COMBO_COUNTRIES = "SET_LIST_COMBO_COUNTRIES";
export const SET_LIST_LAST_UPDATE = "SET_LIST_LAST_UPDATE";
export const SET_LIST_TENDER_STATUS = "SET_LIST_TENDER_STATUS";
export const SET_LIST_ASSIGNED_TO = "SET_LIST_ASSIGNED_TO";
export const SET_LIST_ASSIGNED_BY = "SET_LIST_ASSIGNED_BY";
export const SET_LIST_CONTACT_BY = "SET_LIST_CONTACT_BY";
export const SET_LIST_VISIBILITY_TYPE_CODE = "SET_LIST_VISIBILITY_TYPE_CODE";
export const SET_LIST_PRESET_PIPELINE_ALERT = "SET_LIST_PRESET_PIPELINE_ALERT";
export const SET_LIST_PRESET_ACCOUNT_ALERT = "SET_LIST_PRESET_ACCOUNT_ALERT";
export const SET_LIST_INQUIRIES_TYPE = "SET_LIST_INQUIRIES_TYPE";
export const SET_LIST_CONSTRUCTION_START_DATE =
  "SET_LIST_CONSTRUCTION_START_DATE";
export const SET_LIST_TENDER_CLOSING_DATE = "SET_LIST_TENDER_CLOSING_DATE";
export const SET_LIST_GREEN_BUILDING_STATUS = "SET_LIST_GREEN_BUILDING_STATUS";
export const SET_LIST_GREEN_BUILDING_SCHEME = "SET_LIST_GREEN_BUILDING_SCHEME";
export const RESET_ALL_COMBOS = "RESET_ALL_COMBOS";
export const SET_LIST_COMBO_LOCATION_LOAD = "SET_LIST_COMBO_LOCATION_LOAD";
export const SET_LIST_COMBO_CATEGORY_LOAD = "SET_LIST_COMBO_CATEGORY_LOAD";
export const SET_LIST_COMBO_STAGE_LOAD = "SET_LIST_COMBO_STAGE_LOAD";
export const SET_LIST_COMBO_ROLE_LOAD = "SET_LIST_COMBO_ROLE_LOAD";
export const SET_LIST_LAST_UPDATE_LOAD = "SET_LIST_LAST_UPDATE_LOAD";
export const SET_LIST_CONSTRUCTION_START_DATE_LOAD =
  "SET_LIST_CONSTRUCTION_START_DATE_LOAD";
export const SET_LIST_TENDER_CLOSING_DATE_LOAD =
  "SET_LIST_TENDER_CLOSING_DATE_LOAD";
export const SET_LIST_DEVELOPMENT_TYPE_LOAD = "SET_LIST_DEVELOPMENT_TYPE_LOAD";
export const SET_LIST_OWNERSHIP_TYPE_LOAD = "SET_LIST_OWNERSHIP_TYPE_LOAD";
export const SET_LIST_GREEN_BUILDING_STATUS_LOAD =
  "SET_LIST_GREEN_BUILDING_STATUS_LOAD";
export const SET_LIST_GREEN_BUILDING_SCHEME_LOAD =
  "SET_LIST_GREEN_BUILDING_SCHEME_LOAD";
export const SET_LIST_SALES_PIPELINE_LOAD = "SET_LIST_SALES_PIPELINE_LOAD";
export const SET_LIST_KEY_ACCOUNT_LOAD = "SET_LIST_KEY_ACCOUNT_LOAD";
export const SET_LIST_PROJECT_SAVED_LOAD = "SET_LIST_PROJECT_SAVED_LOAD";
export const SET_LIST_COMPANY_SAVED_LOAD = "SET_LIST_COMPANY_SAVED_LOAD";
export const SET_LIST_TENDER_STATUS_LOAD = "SET_LIST_TENDER_STATUS_LOAD";
export const SET_LIST_APPOINTED_METHOD = "SET_LIST_APPOINTED_METHOD";
export const SET_LIST_APPOINTED_METHOD_LOAD = "SET_LIST_APPOINTED_METHOD_LOAD";
export const SET_LIST_QUOTED = "SET_LIST_QUOTED";
export const SET_LIST_PRODUCT_SPECIFIED = "SET_LIST_PRODUCT_SPECIFIED";
export const SET_LIST_PROBABILITY = "SET_LIST_PROBABILITY";
export const SET_LIST_WON_LOSE = "SET_LIST_WON_LOSE";
export const SET_LIST_QUOTED_LOAD = "SET_LIST_QUOTED_LOAD";
export const SET_LIST_PRODUCT_SPECIFIED_LOAD =
  "SET_LIST_PRODUCT_SPECIFIED_LOAD";
export const SET_LIST_PROBABILITY_LOAD = "SET_LIST_PROBABILITY_LOAD";
export const SET_LIST_WON_LOSE_LOAD = "SET_LIST_WON_LOSE_LOAD";
export const SET_LIST_PREFERENCE_LANGUAGE = "SET_LIST_PREFERENCE_LANGUAGE";
export const SET_LIST_PREFERENCE_LANGUAGE_LOAD =
  "SET_LIST_PREFERENCE_LANGUAGE_LOAD";
export const SET_LIST_COMPANY_GROUP = "SET_LIST_COMPANY_GROUP";
export const SET_LIST_COMPANY_GROUP_LOAD = "SET_LIST_COMPANY_GROUP_LOAD";
export const SET_LIST_PROJECT_CF = "SET_LIST_PROJECT_CF";
export const SET_LIST_PROJECT_CF_LOAD = "SET_LIST_PROJECT_CF_LOAD";
export const SET_LIST_COMPANY_CF = "SET_LIST_COMPANY_CF";
export const SET_LIST_COMPANY_CF_LOAD = "SET_LIST_COMPANY_CF_LOAD";
export const SET_LIST_ROLE_STATUS = "SET_LIST_ROLE_STATUS";
export const SET_LIST_ROLE_STATUS_LOAD = "SET_LIST_ROLE_STATUS_LOAD";
