import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import CommonRoute from "./routes/CommonRoute";
import PrivateRoute from "./routes/PrivateRoute";

import "./App.scss";
import "simplebar-react/dist/simplebar.min.css";
import appConfig from "./config/app.config";
import TNLMAppBanner from "./components/TNLMAppBanner";
import { isAndroid, isIOS } from "./utils/constants";

const MainLayout = React.lazy(() => import("./layout/MainLayout/MainLayout"));
const DocumentationMainLayout = React.lazy(
  () => import("./layout/DocumentationMainLayout")
);
// const Login = React.lazy(() => import("./pages/Auth"));
const Callback = React.lazy(() => import("./pages/Auth/Callback"));
const Logout = React.lazy(() => import("./pages/Auth/Logout"));
const SetPassword = React.lazy(() => import("./pages/Auth/SetPassword"));
const ForgotPassword = React.lazy(() => import("./pages/Auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/Auth/ResetPassword"));

// Outside System validation
const UnsubscribePage = React.lazy(() => import("./pages/Auth/Unsubscribe"));
const DownloadFilePage = React.lazy(() => import("./pages/DownloadFile"));
const ExClientReturnPage = React.lazy(
  () => import("./pages/misc/ExClientReturn")
);

const App = () => {
  return (
    <>
      {window.innerWidth < 1025 && (isIOS || isAndroid) && <TNLMAppBanner />}
      <Routes>
        {appConfig.tnlmEnv !== "PRODUCTION" && (
          <Route path="docs/*" element={<DocumentationMainLayout />} />
        )}

        {/* Unsubscribe */}
        <Route path="/unsubscribe" element={<UnsubscribePage />} />
        {/* Download File */}
        <Route path="/download-file" element={<DownloadFilePage />} />
        {/* Ex Client Opportunity */}
        <Route path="/ex-client-opportunity" element={<ExClientReturnPage />} />

        {/* Login for developer only work when env == development */}
        {/* <CommonRoute path="/login" element={Login} /> */}

        <Route path="callback" element={<CommonRoute />}>
          <Route index element={<Callback />} />
        </Route>
        <Route path="account/set-password" element={<CommonRoute />}>
          <Route index element={<SetPassword />} />
        </Route>
        <Route path="account/reset-password" element={<ResetPassword />}>
          <Route index element={<Callback />} />
        </Route>
        <Route path="account/forgot-password" element={<ForgotPassword />}>
          <Route index element={<Callback />} />
        </Route>

        <Route path="/*" element={<PrivateRoute />}>
          <Route
            path="*"
            element={<Navigate to={"/main/dashboard"} replace />}
          />
          <Route path="logout" element={<Logout />} />
          <Route path="main/*" element={<MainLayout />} />
        </Route>

        {/* { appConfig.isDevelopment !== 'development' && <Route path="/main" component={MainLayout} /> } */}

        {/* <Redirect from="/" to="/main/dashboard" /> */}
        <Route path="*" element={<Navigate to={"/main/dashboard"} replace />} />
      </Routes>
    </>
  );
};

export default App;
