import initialState from "./initialState";
import {
  RESET_FILTER_PROJECT,
  RESET_SEARCH_PROJECT_FILTER,
  RESET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION,
  RESET_SEARCH_PROJECT_PARAMETERS,
  SET_SEARCH_PROJECT_DATA,
  SET_SEARCH_PROJECT_DATA_ERROR,
  SET_SEARCH_PROJECT_DATA_LOAD,
  SET_SEARCH_PROJECT_DATA_VALUE,
  SET_SEARCH_PROJECT_DATA_VALUE_ERROR,
  SET_SEARCH_PROJECT_DATA_VALUE_LOAD,
  SET_SEARCH_PROJECT_DETAIL,
  SET_SEARCH_PROJECT_DETAIL_DATA,
  SET_SEARCH_PROJECT_DETAIL_DATA_LOAD,
  SET_SEARCH_PROJECT_FILTER,
  SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION,
  SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION_ERROR,
  SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION_LOAD,
  SET_SEARCH_PROJECT_PARAMETERS,
  SET_SEARCH_PROJECT_SELECTED,
  SET_SEARCH_PROJECTID_SELECTED,
  SET_SEARCH_PROJECT_WATCHLIST_FORM,
  SET_SEARCH_PROJECT_ENQUIRY_FORM,
  SET_SEARCH_PROJECT_SAVE_SEARCH,
  RESET_SEARCH_PROJECTID_SELECTED,
  SET_SHOW_ALL_DATA_ON_MAP
} from "./types";

const filterProject = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_PROJECT_PARAMETERS: {
      return {
        ...state,
        parameters: { ...state.parameters, ...payload },
        filter: { ...state.filter, offset: 0 },
      };
    }
    case RESET_SEARCH_PROJECT_PARAMETERS: {
      return {
        ...state,
        parameters: { ...initialState.parameters, ...payload },
        filter: { ...state.filter, offset: 0 },
      };
    }
    case SET_SEARCH_PROJECT_FILTER: {
      return {
        ...state,
        filter: { ...state.filter, ...payload, refreshMap: payload.refreshMap !== undefined ? payload.refreshMap : undefined },
      };
    }
    case RESET_SEARCH_PROJECT_FILTER: {
      return {
        ...state,
        filter: initialState.filter,
      };
    }
    case SET_SEARCH_PROJECT_SELECTED: {
      return {
        ...state,
        selected: payload,
        detail: null,
        detailData: null,
      };
    }

    case SET_SEARCH_PROJECTID_SELECTED: {
      return {
        ...state,
        selectedProjectId: payload,
        detail: null,
        detailData: null,
      };
    }
    case RESET_SEARCH_PROJECTID_SELECTED: {
      return {
        ...state,
        selectedProjectId: [],
        detail: null,
        detailData: null,
      };
    }
    case SET_SEARCH_PROJECT_DETAIL: {
      return {
        ...state,
        detail: payload,
        detailData: null,
      };
    }
    case SET_SEARCH_PROJECT_DETAIL_DATA: {
      return {
        ...state,
        detailData: {
          ...initialState.detailData,
          detailData: payload,
        },
      };
    }
    case SET_SEARCH_PROJECT_DETAIL_DATA_LOAD: {
      return {
        ...state,
        detailData: {
          ...initialState.detailData,
          loadData: true,
        },
      };
    }

    // API CALL PROCESS PROJECT DATA
    case SET_SEARCH_PROJECT_DATA_LOAD: {
      return {
        ...state,
        resultData: {
          ...initialState.resultData,
          loadData: true,
        },
        selected: [],
        detail: null,
        detailData: null,
      };
    }
    case SET_SEARCH_PROJECT_DATA_ERROR: {
      return {
        ...state,
        resultData: {
          ...initialState.resultData,
          error: payload || "Something went wrong",
        },
      };
    }
    case SET_SEARCH_PROJECT_DATA: {
      return {
        ...state,
        resultData: {
          ...initialState.resultData,
          projects: payload.data,
          totalDataProjectAll: payload.totalDataProjectAll,
          totalValue: payload.totalValue,
          currency: payload.currency,
          outsideSubsCount: payload.outsideSubsCount,
        },
      };
    }

    case SET_SEARCH_PROJECT_DATA_VALUE_LOAD: {
      return {
        ...state,
        resultDataValue: {
          ...initialState.resultDataValue,
          loadDataValues: true,
        },
        selected: [],
        detail: null,
        detailData: null,
      };
    }
    case SET_SEARCH_PROJECT_DATA_VALUE_ERROR: {
      return {
        ...state,
        resultDataValue: {
          ...initialState.resultDataValue,
          error: payload || "Something went wrong",
        },
      };
    }
    case SET_SEARCH_PROJECT_DATA_VALUE: {
      return {
        ...state,
        resultDataValue: {
          ...initialState.resultDataValue,
          totalValue: payload.totalValue,
          totalData: payload.totalData,
          currencyCode: payload.currencyCode,
          outsideSubsCount: payload.outsideSubsCount,
        },
      };
    }

    case SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION_LOAD: {
      return {
        ...state,
        resultDataOutsideSubscription: {
          ...initialState.resultDataOutsideSubscription,
          loadSubs: true,
        },
      };
    }
    case SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION_ERROR: {
      return {
        ...state,
        resultDataOutsideSubscription: {
          ...initialState.resultDataOutsideSubscription,
          error: payload || "Something went wrong",
        },
      };
    }
    case SET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION: {
      return {
        ...state,
        resultDataOutsideSubscription: {
          ...initialState.resultDataOutsideSubscription,
          outsideSubscriptions: payload.data,
          totalDataSubs: payload.totalData,
        },
      };
    }
    case RESET_SEARCH_PROJECT_OUTSIDE_SUBSCRIPTION: {
      return {
        ...state,
        resultDataOutsideSubscription:
          initialState.resultDataOutsideSubscription,
      };
    }
    case SET_SEARCH_PROJECT_WATCHLIST_FORM: {
      return {
        ...state,
        projectWatchlistForm: payload,
      };
    }
    case SET_SEARCH_PROJECT_ENQUIRY_FORM: {
      return {
        ...state,
        projectEnquiryForm: !state.projectEnquiryForm,
      };
    }
    case SET_SEARCH_PROJECT_SAVE_SEARCH: {
      return {
        ...state,
        saveSearch: payload,
      };
    }
    case RESET_FILTER_PROJECT: {
      return initialState;
    }
    case SET_SHOW_ALL_DATA_ON_MAP: {
      return {
        ...state,
        showAllDataOnMap: payload ?? true,
      };
    }
    default:
      return state;
  }
};

export default filterProject;
